import React from "react"

import Button from "components/Button";

const BootcampCard = ({ bootcamp, careers, language, slug, location }) => {

  const i18n = {
    description: {
      en: "Description",
      de: "Beschreibung"
    },
    courses: {
      en: "Course(s):",
      de: "Kurs(e):"
    },
    learnMore: {
      en: "Learn more",
      de: "MEHR ERFAHREN"
    },
    career: {
      en: "Career",
      de: "Karriere"
    },
    location: {
      en: "Location",
      de: "Ort"
    },
    fundingOptions: {
      en: "Funding Options",
      de: "Finanzierung"
    },
    defaultFundingOption: {
      en: "All funding options",
      de: "Alle Finanzierungsmöglichkeiten"
    },
  };

  const renderDescription = (description) => {
    if (description.length > 190) {
      const cutDescription = description.substring(0, 190)
      return cutDescription.concat("...")
    } else {
      return description
    }
  }


  return (
    <>
      <div>
        <div className="bootcamp__header">
          <p className="bootcamp__big_header">{bootcamp.partner_name}</p>
          <img className="partner-logo" src={bootcamp.partner_logo} alt="" />
        </div>
      </div>
      <div className="pill-container">
        {(careers
          .map(career => (
            <p key={career.value} className={`pill font-semibold ${career.color}`}>{career.label[language]}</p>
          )))}
      </div>
      <p className="bootcamp__small_header">{i18n.description[language]}</p>
      <p className="base-text">{renderDescription(bootcamp.partner_text)}</p>
      <p className="bootcamp__small_header">{i18n.courses[language]}</p>
      <div className="base-text">
        {bootcamp.courses_detail.map?.(({ course }, index) => (
          <p key={index}><span className="purple-circle">●</span>{course.title}</p>
        ))}
      </div>
      <div className="bootcamp__footer">
        <div className="location-container">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0001 9.58366C9.44755 9.58366 8.91764 9.36417 8.52694 8.97346C8.13624 8.58276 7.91675 8.05286 7.91675 7.50033C7.91675 6.94779 8.13624 6.41789 8.52694 6.02719C8.91764 5.63649 9.44755 5.41699 10.0001 5.41699C10.5526 5.41699 11.0825 5.63649 11.4732 6.02719C11.8639 6.41789 12.0834 6.94779 12.0834 7.50033C12.0834 7.77391 12.0295 8.04482 11.9248 8.29758C11.8201 8.55034 11.6667 8.78001 11.4732 8.97346C11.2798 9.16692 11.0501 9.32038 10.7973 9.42508C10.5446 9.52977 10.2737 9.58366 10.0001 9.58366ZM10.0001 1.66699C8.45299 1.66699 6.96925 2.28157 5.87529 3.37554C4.78133 4.4695 4.16675 5.95323 4.16675 7.50033C4.16675 11.8753 10.0001 18.3337 10.0001 18.3337C10.0001 18.3337 15.8334 11.8753 15.8334 7.50033C15.8334 5.95323 15.2188 4.4695 14.1249 3.37554C13.0309 2.28157 11.5472 1.66699 10.0001 1.66699Z" fill="#481BFF" />
          </svg>
          <p className="md-text">{location.label[language]}</p>
        </div>
        <Button link={`partner/${slug}`} variant="transparent-blue" label={i18n.learnMore[language]} />
      </div>
    </>
  )
}

export default BootcampCard