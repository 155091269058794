const NavigationItems = {
  en: [
    {
      label: 'Home',
      slug: '',
      hideOnDesktop: true,
    },
    {
      label: 'Courses',
      slug: 'courses',
      dropdownWide: true,
      dropdownAlignLeft: true,
      children: [
        {
          label: 'Compass Course',
          subLabel: 'Try all the skills in one month.',
          subSubLabel: 'Full-Time',
          slug: 'compass-course-digital-skills-jobs',
          image: "compass_small.png"
        },
        {
          label: 'Tech: Mentor Course',
          subLabel: 'Pick one skill & study with your own trainer.',
          subSubLabel: 'Part-Time',
          slug: 'tech-mentor',
          image: "rocket.png"
        },
      ]
    },
    {
      label: 'Resources',
      slug: '',
      dropdownWide: false,
      dropdownAlignLeft: false,
      children: [
        {
          label: 'Career Bootcamps',
          subLabel: 'Discover who we work with, as well as professional certificates from Facebook & Google.',
          slug: 'bootcamps-we-work-with',
          image: "hands.png"
        },
      ]
    },
    {
      label: 'About us',
      slug: 'about-us',
    },
    {
      label: 'Apply now',
      slug: 'apply-now',
      isButton: true,
    },
  ],
  de: [
    {
      label: 'Startseite',
      slug: '',
      hideOnDesktop: true,
    },
    {
      label: 'Kurse',
      slug: 'kurse',
      dropdownWide: true,
      dropdownAlignLeft: true,
      children: [
        {
          label: 'Kompass (Digitale Berufe)',
          subLabel: 'Eine Einführung in Programmierung, Marketing, UX/UI & Data',
          subSubLabel: 'Full-Time',
          slug: 'compass-kurs-digital-skills-jobs',
          image: "compass_small.png"
        },
        {
          label: 'Tech:Mentor (Einzelunterricht)',
          subLabel: 'Wähle ein Thema & und lerne mit deinem*r eigenen Lehrer*in.',
          subSubLabel: 'Teilzeit',
          slug: 'tech-mentor',
          image: "rocket.png"
        },
      ]
    },
    {
      label: 'Mehr',
      slug: '',
      dropdownWide: false,
      dropdownAlignLeft: false,
      children: [
        {
          label: 'Bootcamps',
          subLabel: 'Lern’ unsere Partner-Bootcamps kennen sowie unsere Zertifikate in Zusammenarbeit mit Facebook und Google.',
          slug: 'bootcamps-we-work-with',
          image: "hands.png"
        },
      ]
    },
    {
      label: 'Über uns',
      slug: 'über-uns',
    },
    {
      label: 'Los geht\'s',
      slug: 'los-gehts',
      isButton: true,
    },
  ],
};
export default NavigationItems;
