import React, { FC, ReactElement } from "react"

import { Link } from "react-router-dom"

import utils from 'utils';
import './UltraHeader.scss';

import BadgeTitle from "components/BadgeTitle";

export const UltraHeader: FC<{
  backgroundImageUrl: string,
  language: "en" | "de",
  title: string,
  badge: string,
  subtitle: string,
  form: ReactElement
}> = ({ backgroundImageUrl, language, title, badge, subtitle, form }) => {
  console.log("Hello world!")
  return (
    <header className="ultra-header-container">
      <div className="ultra-header full-width-outer">
        <div
          className="ultra-image-background"
          // style={{ backgroundImage: `url(${backgroundImageUrl})` }}
          style={{ backgroundImage: `linear-gradient(to right, rgba(72, 27, 255, 0.9), rgba(72, 27, 255, 0)), url(${backgroundImageUrl})` }}
        >
          <div className="ultra-container z-20">
            <div className="ultra-logo">
              <Link
                to={{
                  pathname: `/${language}`,
                }}
                className="header__logo"
              />
            </div>
            <div className="ultra-title-container">
              <BadgeTitle
                title={title}
                badge={badge}
              />
            </div>
            <div className="ultra-subtitle-mobile" dangerouslySetInnerHTML={{ __html: utils.cleanText(subtitle, true) }}></div>
          </div>
        </div>
      </div>
      <div className="ultra-container">
        <div className="ultra-subtitle" dangerouslySetInnerHTML={{ __html: utils.cleanText(subtitle, true) }}></div>
      </div>
      <div className="ultra-form-container">
        {form}
      </div>
    </header>
  )
}