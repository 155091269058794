import React from 'react';

import utils from 'utils';

import './CourseDetailCard.scss';
import Button from 'components/Button';

const CourseDetailCard = ({ course, logo, bootcamp_name, selectCourse, language }) => {
  const i18n = {
    duration: {
      en: "Duration:",
      de: "Dauer:"
    },
    price: {
      en: "Price:",
      de: "Preis:"
    },
    paymentOptions: {
      en: "Payment Options:",
      de: "Zahlungsoptionen:"
    },
    requirements: {
      en: "Requirements:",
      de: "Anforderungen:"
    },
    interestedButton: {
      en: "I'm interested",
      de: "Ich bin interessiert"
    },
    massnahmennummer: {
      en: "Maßnahmennummer:",
      de: "Maßnahmennummer:"
    },
  };
  return (
    <div className="card">
      <div className="partner-course-flex-between partner-course-flex-between-reverse wrap-text">
        <p className="title">{course.title}</p>
        <img className="partner-course-logo" src={logo} alt="" />
      </div>
      <div className="card-section">
        <p className="subtitle">{bootcamp_name}</p>
        <div className="base-text space-y-md" dangerouslySetInnerHTML={{ __html: utils.cleanText(course.description, true) }}></div>
      </div>
      {course.duration && <div className="card-section">
        <p className="subtitle">{i18n.duration[language]}</p>
        <p className="base-text">{course.duration}</p>
      </div>}
      {course.price && <div className="card-section">
        <p className="subtitle">{i18n.price[language]}</p>
        <p className="base-text">{course.price}</p>
      </div>}
      {course.payment_options && <div className="card-section">
        <p className="subtitle">{i18n.paymentOptions[language]}</p>
        <ul className="payment-list">
          {course.payment_options.map(({ payment_option }) => (
            <li key={payment_option} className="base-text"><span className="list-item">{payment_option}</span></li>
          ))}
        </ul>
      </div>}
      <div className="card-section partner-course-flex-between partner-course-flex-between-end">
        <div className="requirements-section">
          {course.requirements && <>
            <p className="subtitle">{i18n.requirements[language]}</p>
            <ul className="payment-list">
              {course.requirements.map(({ requirement }) => (
                <li key={requirement} className="base-text"><span className="list-item">{requirement}</span></li>
              ))}
            </ul></>}
          {course.voucher && (
            <div className="card-small-section">
              <p className="small-subtitle">{i18n.massnahmennummer[language]}</p>
              <p className="small-text">{course.massnahmennummer}</p>
            </div>
          )}
        </div>
        <Button variant="small" label={i18n.interestedButton[language]} onClick={() => selectCourse({ name: course.title, voucher: course.voucher })} />
      </div>
    </div>)
}

export default CourseDetailCard