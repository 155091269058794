import React from "react";

import "./BootcampsHero.scss"
import Button from "../Button/index"

const BootcampsHero = ({ title, content, image, buttonText }) => {
    return (<header className="bootcamps-hero">
        <div
            className="full-width-outer bootcamps-image"
            style={{
                backgroundImage: `url(${image})`,
            }}
        >
            <div className="header-reskill__gradient">
                <div className="bootcamps-hero-container">
                    <div className="small-container">
                        <h1 className="">{title}</h1>
                        <p className="">{content}</p>
                        {buttonText && <Button label={buttonText} link="courses/compass-course-digital-skills-jobs" variant="transparent-blue-alt2" />}
                    </div>
                </div>
            </div>
        </div>
    </header>)
}

export default BootcampsHero