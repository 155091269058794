const FooterItems = {
  en: {
    slogan: 'Your first step towards the jobs of tomorrow.',
    certificate: 'Certified according to AZAV and the Federal Employment Agency.',
    links: [
      {
        heading: 'Company',
        items: [
          { label: 'ReSkill Germany', slug: 'reskill' },
          { label: 'Partner Bootcamps', slug: 'bootcamps-we-work-with' },
          { label: 'Professional Certificates', slug: 'professional-certificates' },
          { label: 'Events', slug: 'events' },
          { label: 'Careers', slug: 'careers' },
          { label: 'Blog', slug: 'blog' },
        ],
      },
      {
        heading: 'Resources',
        items: [
          { label: 'Why is it free?', slug: 'why-is-it-free' },
          { label: 'Compass Tool', slug: 'compass-tool' },
          { label: 'FAQ', slug: 'faq' },
          { label: 'For AA / Jobcenter', slug: 'jobcenter' },
          { label: 'Contact', slug: 'contact' },
        ],
      },
      {
        heading: 'Be Social',
        items: [
          { label: 'LinkedIn', slug: 'https://www.linkedin.com/company/startsteps/' },
          { label: 'Instagram', slug: 'https://www.instagram.com/startsteps_germany/' },
          { label: 'Facebook', slug: 'https://www.facebook.com/startsteps' },
          { label: 'Spotify', slug: 'https://open.spotify.com/playlist/3HoMwKKoODcODB7dZyWyVW' },
        ],
      },
      {
        heading: 'Legal',
        items: [
          { label: 'Disclaimer', slug: 'legal/disclaimer' },
          { label: 'Privacy', slug: 'legal/privacy' },
          { label: 'Imprint', slug: 'legal/imprint' },
        ],
      },
    ],
  },
  de: {
    slogan: 'Your first step towards the jobs of tomorrow.',
    certificate: 'Zugelassener Träger nach AZAV (Akkreditierungs- und Zulassungsverordnung).',
    links: [
      {
        heading: 'Firma',
        items: [
          { label: 'ReSkill Germany', slug: 'reskill', lang: 'en' },
          { label: 'Partner Bootcamps', slug: 'bootcamps-we-work-with' },
          { label: 'Professionelle Zertifikate', slug: 'professional-certificates' },
          { label: 'Events', slug: 'events' },
          { label: 'Karriere', slug: 'karriere' },
          { label: 'Blog', slug: 'blog' },
        ],
      },
      {
        heading: 'Ressourcen',
        items: [
          { label: 'Warum ist es kostenlos?', slug: 'warum-ist-es-kostenlos' },
          { label: 'Compass Tool', slug: 'compass-tool', lang: 'en' },
          { label: 'FAQ', slug: 'faq' },
          { label: 'For AA / Jobcenter', slug: 'jobcenter' },
          { label: 'Kontakt', slug: 'kontakt' },
        ],
      },
      {
        heading: 'Be Social',
        items: [
          { label: 'LinkedIn', slug: 'https://www.linkedin.com/company/startsteps/' },
          { label: 'Instagram', slug: 'https://www.instagram.com/startsteps_germany/' },
          { label: 'Facebook', slug: 'https://www.facebook.com/startsteps' },
          { label: 'Spotify', slug: 'https://open.spotify.com/playlist/3HoMwKKoODcODB7dZyWyVW' },
        ],
      },
      {
        heading: 'Legal',
        items: [
          { label: 'Disclaimer', slug: 'legal/disclaimer' },
          { label: 'Privacy', slug: 'legal/privacy' },
          { label: 'Imprint', slug: 'legal/imprint' },
        ],
      },
    ],
  },
};
export default FooterItems;
