import React, { useEffect } from "react"

import { useCookies } from "react-cookie"

import {
    useLocation,
} from 'react-router-dom';

export const getReferral = (referralCookie) => {
    if (referralCookie) {
        return ` - Referred by ${referralCookie}`
    } else {
        return ""
    }
}


const ReferralWrapper = ({ children }) => {
    const { search } = useLocation()
    /* eslint-disable */
    const [cookies, setCookie, removeCookie] = useCookies();

    const query = new URLSearchParams(search)

    useEffect(() => {
        const referralQuery = query.get("ref")
        if (referralQuery) {
            setCookie("ref", referralQuery, {expires: new Date(2022, 20, 10), path: "/"})
        }
    }, [])

    return (
        <>{children}</>
    )
}

export default ReferralWrapper