import React, { useEffect } from "react"

import { useCookies } from "react-cookie"

export const getSession = (sessionCookie) => {
    return sessionCookie
}

export const createEventId = () => {
    return createRandomString()
}

const createRandomString = () => {
    return Math.random().toString(36).substr(2, 9)
}

const SessionWrapper = ({ children }) => {
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        if (!cookies.session) {
            const uniqueString = createRandomString()
            window.fbq('init', '690276994903670', { external_id: uniqueString });
            setCookie("session", uniqueString, { expires: new Date(2022, 20, 10), path: "/" })
        }
    }, [cookies.session, setCookie])

    return (
        <>{children}</>
    )
}

export default SessionWrapper