import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Button from 'components/Button';

// Styles
import './CookieBanner.scss';

const addTrackingScripts = () => {
  const headTag = document.getElementsByTagName('head')[0];
  const scripts = [
    { // Pipedrive
      src: 'https://sc.lfeeder.com/lftracker_v1_p1e024BPQBp8GB6d.js',
      beforeAdd: () => { window.ldfdr = window.ldfdr || {}; },
    },
    { // Hotjar
      src: 'https://static.hotjar.com/c/hotjar-1998479.js?sv=6',
      async: true,
      beforeAdd: () => {
        window.hj = window.hj || function() {
          (window.hj.q = window.hj.q || []).push(arguments);
        };
        window._hjSettings = { hjid: 1998479, hjsv: 6 };
      },
    },
    { // Google Analytics
      src: 'https://www.googletagmanager.com/gtag/js?id=UA-177402519-1',
      async: true,
      beforeAdd: () => {
        /* eslint-disable */
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-177402519-1');
        /* eslint-enable */
      },
    },
    { // Google Tag Manager
      src: 'https://www.googletagmanager.com/gtm.js?id=GTM-M52KWLH',
      async: true,
      beforeAdd: () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
      },
    },
  ];

  scripts.forEach((script) => {
    if (script.beforeAdd) {
      script.beforeAdd();
    }
    var s = document.createElement('script');
    s.src = script.src;
    if (script.async) {
      s.async = script.async;
    }
    headTag.appendChild(s);
  });
};
const mayAddTrackingScripts = localStorage.getItem('mayAddTrackingScripts');
// if (mayAddTrackingScripts === 'true') {
  addTrackingScripts();
// }

export default function CookieBanner() {
  const [isVisible, setIsVisible] = useState(mayAddTrackingScripts !== null ? false : true);
  const locationBits = useLocation().pathname.split('/');
  const currentLanguage = locationBits[1];
  let cookieText = 'This site uses cookies to provide you with a great user experience. Do you accept our use of cookies?';
  let linkText = 'Learn more';
  if (currentLanguage === 'de') {
    cookieText = 'Diese Webseite verwendet Cookies, um Ihnen eine optimale Benutzererfahrung zu bieten. Sind Sie mit der Verwendung von Cookies einverstanden?';
    linkText = 'Mehr erfahren';
  }

  if (!isVisible) {
    return null;
  }
  return (
    <div className="cookie-banner">
      <div className="full-width-outer">
        <div className="full-width-inner">
          <div className="row">
            <div className="col-1 col-0-sm" />
            <div className="col-10 col-12-sm">
              <div className="cookie-banner__inner">
                <div className="row">
                  <div className="col-1">
                    <div className="cookie-banner__image">
                      <span
                        aria-label="Cookie"
                        role="img"
                      >🍪</span>
                    </div>
                  </div>
                  <div className="col-7 col-11-sm">
                    { cookieText }
                    <a
                      className="cookie-banner__more"
                      href="/en/legal/cookie-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      { linkText }
                    </a>
                  </div>
                  <div className="col-4 col-12-sm">
                    <div className="cookie-banner__buttons">
                      <Button
                        label="Yes I do"
                        onClick={ () => {
                          localStorage.setItem('mayAddTrackingScripts', true);
                          // addTrackingScripts();
                          setIsVisible(false);
                        }}
                      />
                      <Button
                        label="No"
                        onClick={ () => {
                          localStorage.setItem('mayAddTrackingScripts', false);
                          setIsVisible(false);
                        }}
                        variant="transparent-blue"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 col-0-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}
