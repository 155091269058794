import React from "react"
import Community from "components/Community"
import Students from "components/Students"
import ImageText from "components/ImageText"
import ThreeColumnImage from "components/ThreeColumnImage"
import Bootcamps from "components/Bootcamps"
import Timeline from "components/Timeline"
import Page from './Page';
import { withCookies } from "react-cookie"
import { VideoBlock } from "components/VideoBlock"
import { UltraHeader } from "components/UltraHeader"

import "./FirstSteps.scss"
import { Form } from "components/Form"


export class FirstStepsPage extends Page {
  componentDidMount() {
    super.componentDidMount(undefined);
  }
  render() {
    const { content } = this.state;

    if (!content) {
      return super.render();
    }
    console.log(content)

    return (
      <div className="first-steps">
        <UltraHeader
          backgroundImageUrl={content.background_image}
          language={this.props.language}
          title={content.landingpage_title}
          badge={content.landingpage_badge}
          subtitle={content.landingpage_text}
          form={<Form
            text={content.landingpage_form_text}
            firstNameLabel={content.landingpage_first_name_input_label}
            lastNameLabel={content.landingpage_last_name_input_label}
            emailLabel={content.landingpage_email_input_label}
            phoneLabel={content.landingpage_phone_input_label}
            buttonLabel={content.landingpage_button_label}
            jobcenterLabel={content.landingpage_registered_input_label}
            jobcenterErrorMessage={content.landingpage_registered_error_message}
            jobcenterOptions={content.landingpage_registered_items}
            jobcenterTooltip={content.registered_tooltip}
            successMessage={content.landingpage_message}
            language={this.props.language}
            // 1 = New Lead in Pipeline
            stageId={1}
            leadSource={"Landing Page 2.0"}
          />}
        />
        <section className="image-text-section">
          <ImageText
            title={content.imagetext_title}
            text={content.imagetext_text}
            image={content.imagetext_image}
          />
        </section>
        <ThreeColumnImage
          title={content.threecolumnimage_title}
          text={content.threecolumnimage_text}
          items={content.threecolumnimage_items}
        />
        <Bootcamps
          title={content.bootcamps_title}
          items={content.bootcamps_items}
        />
        <Timeline
          title={content.timeline_title}
          text={content.timeline_text}
          items={content.timeline_items}
        />
        <section className="community-container">
          <Community
            title={content.community_title}
            text={content.community_text}
            items={content.community_items}
            bottomTitle={content.community_bottom_title}
            bottomTitleSub={content.community_bottom_title_sub}
            bottomButtonLabel={content.community_bottom_button_label}
            onlyFileDownload={content.only_file_download}
          />
        </section>
        <section className="video-block-container">
          <VideoBlock
            title={content.title}
            subtitle={content.subtitle}
            youtubeVideoId={content.youtube_video_id}
            image={content.image}
          />
        </section>
        <section className="students-container">
          <Students
            title={content.students_title}
            text={content.students_text}
            buttonLabel={content.students_tooltip_button_label}
            buttonLink={content.students_tooltip_button_link}
            items={content.students_items}
            showOnlyStudents={content.show_only_students}
          />
        </section>
      </div>
    )
  }
}

export default withCookies(FirstStepsPage)