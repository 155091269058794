import { createEventId } from "./components/Session"
const { ServerEvent, EventRequest, UserData, CustomData } = require('facebook-nodejs-business-sdk')
const sanitizeHtml: any = require('sanitize-html');

// const subDomain = window.location.hostname === 'staging.startsteps.org' ? 'staging-wp' : 'wordpress';
const subDomain = 'wordpress';
const wpBaseURL = `https://${subDomain}.startsteps.org/wp-json`;
const websiteName = 'StartSteps: Digital Skills & the Jobs of the Future';

// Pipedrive
const pipedriveApiKey = '4e4a09a1d04efb4a7d7c8ec8d40d7624c5afb244';
const pipedriveApiPersonUrl = `https://api.pipedrive.com/v1/persons?api_token=${pipedriveApiKey}`;
const pipedriveApiDealUrl = `https://api.pipedrive.com/v1/deals?api_token=${pipedriveApiKey}`;
const pipedriveOwnerId = 11556668;

// Recaptcha
const recaptchaApiUrl = 'https://startsteps.org/recaptcha-check.php';
const recaptchaSiteKey = '6LdKe8kZAAAAAGGy28VytGxoi6PCcD0IpcyNUeN9';

// Eventbrite
const eventbritePrivateToken = 'I6DCL4KJGEJBMCSVKEPO';
const eventbriteApiUrl = 'https://www.eventbriteapi.com/v3/organizations/31121003697/events/';

// Facebook Pixel
const facebookAccessToken = 'EAAEg5ZCH9u18BABC8oyD9cizgsicl3z4P0aFOvLCVnukSdjjfoPbqfgHyziGyb90VPJnxRfNFAlO4iaVQRNlAiaQ8fzLqRXr6OQ150BKZBER5ZC9xn0oR9Xm2ofvaJ0mqSY7dngCtJKGByZAefIOJf6vDMR2a2ZCOZAZAQf4k1bD3oObhn7rqyv1a3Ud7eV1ZBsZD';
const facebookPixelId = '690276994903670'

// SEO
const pageTitle = document.querySelector('title');
const cleanDescription = (str) => {
  return sanitizeHtml(str, { allowedTags: [] }).replace(/\n/g, '');
};

const api = {
  getBlogPosts(language = 'en', perPage = 10) {
    const languageSuffix = language === 'en' ? '' : `&lang=${language}`;
    return new Promise((resolve, reject) => {
      fetch(`${wpBaseURL}/wp/v2/posts?per_page=${perPage}${languageSuffix}`)
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBlogPost(language = 'en', slug = null) {
    const languageSuffix = language === 'en' ? '' : `&lang=${language}`;
    return new Promise((resolve, reject) => {
      fetch(`${wpBaseURL}/wp/v2/posts?slug=${slug}${languageSuffix}`)
        .then((response) => response.json())
        .then((data) => {
          resolve(data[0] || {});
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getCompassWidget(language: "en" | "de"): Promise<any> {
    const languageSuffix = language === 'en' ? '' : `&lang=${language}`;
    const compassWidgetPageResponse = await fetch(`${wpBaseURL}/wp/v2/pages?slug=compass-widget${languageSuffix}`)
    const compassWidgetPage = await compassWidgetPageResponse.json()
    const acfId = compassWidgetPage[0].id
    const acfResponse = await fetch(`${wpBaseURL}/acf/v3/pages/${acfId}`)
    const acf = await acfResponse.json()
    return acf.acf
  },
  async getBootcamps(language = 'en') {
    const result = await fetch(`${wpBaseURL}/wp/v2/pages?slug=bootcamps-we-work-with${language === 'en' ? '' : `&lang=${language}`}`);
    const pageData = await result.json()
    const bootcampsAcf = await fetch(`${wpBaseURL}/acf/v3/pages/${pageData[0].id}`);
    const bootcampsData = await bootcampsAcf.json()
    const bootCampPageRequests: any = await Promise.all(
      bootcampsData.acf.bootcamps
        .map(async (bootcamp) => await ({ res: await fetch(`${wpBaseURL}/acf/v3/pages/${bootcamp.ID}`), slug: bootcamp.post_name }))

    );

    const bootcampsJson: any = await Promise.all(bootCampPageRequests.map(async (pageResult) => await ({ data: await pageResult.res.json(), slug: pageResult.slug })));
    const bootcamps = bootcampsJson.map((dataAndSlug) => ({ bootcamp: dataAndSlug.data.acf, slug: dataAndSlug.slug }))

    return {
      bootcamps: bootcamps,
      title: bootcampsData.acf.title,
      content: bootcampsData.acf.content,
      image: bootcampsData.acf.image,
      buttonText: bootcampsData.acf.button_text,
      bootcampsListTitle: bootcampsData.acf.bootcamps_list_title,
    }
  },
  async getProfessionalCertificates(language) {
    const languageSuffix = language === 'en' ? '' : `&lang=${language}`;
    const result = await fetch(`${wpBaseURL}/wp/v2/pages?slug=professional-certificates${languageSuffix}`);
    const pageData = await result.json()
    const bootcampsAcf = await fetch(`${wpBaseURL}/acf/v3/pages/${pageData[0].id}`);
    const bootcampsData = await bootcampsAcf.json()
    const bootCampPageRequests: any = await Promise.all(
      bootcampsData.acf.bootcamps
        .map(async (bootcamp) => await ({ res: await fetch(`${wpBaseURL}/acf/v3/pages/${bootcamp.ID}`), slug: bootcamp.post_name }))

    );

    const bootcampsJson: any = await Promise.all(bootCampPageRequests.map(async (pageResult) => await ({ data: await pageResult.res.json(), slug: pageResult.slug })));
    const bootcamps = bootcampsJson.map((dataAndSlug) => ({ bootcamp: dataAndSlug.data.acf, slug: dataAndSlug.slug }))

    return {
      bootcamps: bootcamps,
      title: bootcampsData.acf.title,
      content: bootcampsData.acf.content,
      image: bootcampsData.acf.image,
      bootcampsListTitle: bootcampsData.acf.bootcamps_list_title,
      bootcampsListDescription: bootcampsData.acf.bootcamps_list_description,
    }
  },
  getContent(slug, language = 'en', skipTitle = false) {
    if (!skipTitle) {
      pageTitle.innerHTML = `Loading… &mdash; ${websiteName}`;
    }
    const languageSuffix = language === 'en' ? '' : `&lang=${language}`;
    return new Promise((resolve, reject) => {

      // Find page by slug
      fetch(`${wpBaseURL}/wp/v2/pages?slug=${slug}${languageSuffix}`)
        .then((response) => response.json())
        .then((data) => {
          const page = data[0];
          if (page && page.id) {
            if (!skipTitle) {
              pageTitle.innerHTML = `${page.title.rendered} &mdash; ${websiteName}`;
              // pageDescription.innerHTML = cleanDescription(page.content.rendered);
              let pageDescription = document.querySelector('meta[name="description"]');
              if (!pageDescription) {
                const head = document.getElementsByTagName("head").item(0)
                const descriptionElement = document.createElement("meta")
                descriptionElement.setAttribute("name", "description")
                descriptionElement.setAttribute("content", cleanDescription(page.content.rendered))
                head.appendChild(descriptionElement)
              } else {
                pageDescription.setAttribute("content", cleanDescription(page.content.rendered))
              }
            }

            // Get Advanced Custom Fields for this page
            fetch(`${wpBaseURL}/acf/v3/pages/${page.id}`)
              .then((response) => response.json())
              .then((data) => {
                resolve({ content: data.acf, title: page.title.rendered });
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject(`No page found for slug ${slug}.`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async trackFbEvent({
    eventName,
    externalId,
    firstName,
    lastName,
    email,
    phone,
    country,
    city,
    customDataObj
  }: {
    eventName: string,
    externalId: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    country?: string,
    city?: string,
    customDataObj: {
      [key: string]: string | number
    }
  }): Promise<void> {
    try {
      const eventId = createEventId()
      const userData = new UserData()
      userData.setClientUserAgent(navigator.userAgent || '')
      userData.setExternalId(externalId)

      if (firstName) {
        userData.setFirstName(firstName)
      }
      if (lastName) {
        userData.setLastName(lastName)
      }
      if (email) {
        userData.setEmail(email)
      }
      if (phone) {
        userData.setPhone(phone)
      }
      if (country) {
        userData.setCountry(country)
      }
      if (city) {
        userData.setCity(city)
      }

      const customData = new CustomData()
      customData.setCustomProperties(customDataObj)

      const serverEvent = new ServerEvent()
      serverEvent.setEventName(eventName)
      serverEvent.setEventTime(Math.floor(new Date().getTime() / 1000))
      serverEvent.setUserData(userData)
      serverEvent.setActionSource("website")
      serverEvent.setEventId(eventId)
      serverEvent.setCustomData(customData)

      const eventRequest = new EventRequest(
        facebookAccessToken,
        facebookPixelId
      ).setEvents([serverEvent]);

      (window as any).fbq(
        "track",
        eventName,
        {
        },
        {
          eventID: eventId,
        }
      )
      const res = await eventRequest.execute();
      res.messages.forEach(message => console.log(message))
    } catch (e) {
      console.error(e.message)
    }
  },
  getStages() {
    fetch(`https://api.pipedrive.com/v1/stages/?api_token=${pipedriveApiKey}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
      });
    fetch(`https://api.pipedrive.com/v1/pipelines/?api_token=${pipedriveApiKey}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
      });
  },
  getCustomFields() {
    fetch(`https://api.pipedrive.com/v1/personFields/?api_token=${pipedriveApiKey}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);
      });
  },
  addDeal(title, person_id, setPipedriveStage): Promise<void> {
    const data = {
      title,
      person_id,
      user_id: pipedriveOwnerId,
      visible_to: 3,
      ...setPipedriveStage
        ? { stage_id: setPipedriveStage }
        : {}
    };
    return new Promise((resolve, reject) => {
      fetch(pipedriveApiDealUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            resolve();
          } else {
            reject(`${data.error}: ${data.error_info}`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addPerson(first_name, last_name, email, customFields = {}, formType = '', setPipedriveStage = undefined): Promise<void> {
    const data = {
      name: `${first_name} ${last_name}`,
      email,
      ...customFields,
      owner_id: pipedriveOwnerId,
      visible_to: 3,
    };
    return new Promise((resolve, reject) => {
      this.recaptchaCheck()
        .then(() => {
          fetch(pipedriveApiPersonUrl, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({
                  event: 'formSubmission',
                  formType,
                });
                this.addDeal(data.data.name, data.data.id, setPipedriveStage)
                  .then((data) => {
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else {
                reject(`${data.error}: ${data.error_info}`);
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          console.error(error)
          reject(error);
        });
    });
  },
  recaptchaCheck(callback): Promise<void> {
    return new Promise((resolve, reject) => {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha.execute(recaptchaSiteKey, { action: 'submit' })
          .then((token) => {
            fetch(recaptchaApiUrl, {
              method: 'POST',
              cache: 'no-cache',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token,
              }),
            })
              .then((response) => response.json())
              .then((response) => {
                if (response.error) {
                  reject(response.error);
                } else if (response.score >= 0.5) {
                  resolve();
                } else {
                  reject('Something went wrong in recaptchaCheck');
                }
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
  getEvents(): Promise<void> {
    return new Promise((resolve, reject) => {
      fetch(eventbriteApiUrl, {
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${eventbritePrivateToken}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default api;
