import React, { useEffect } from "react"

import api from 'api';

import {
  useLocation
} from 'react-router-dom';
import {
  useCookies
} from 'react-cookie';

import { getSession } from "components/Session";

const PixelWrapper = ({ children }) => {
  const location = useLocation()
  const [cookies] = useCookies()

  useEffect(() => {
    window.ttq.track("Browse")
    const session = getSession(cookies.session);
    if (location.pathname && session) {
      api.trackFbEvent({
        eventName: "PageView",
        externalId: session,
      })
    }
  }, [location.pathname, cookies.session])

  return (
    <>{children}</>
  )
}

export default PixelWrapper