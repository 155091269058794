import React, {
  useEffect,
  useState,
} from 'react';
import {
  Link,
  NavLink,
  useLocation,
} from 'react-router-dom';

// Helper
import api from 'api';
import utils from 'utils';

// Components
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';

// Helper
import NavigationItems from 'navigation';

// Styles
import './Header.scss';
import { useCookies } from 'react-cookie';

const findRelatedPage = (locationBits) => {
  const availableLanguages = Object.keys(NavigationItems);
  const currentLanguage = locationBits[1] || availableLanguages[0];

  let otherLanguage = '';
  availableLanguages.forEach((language) => {
    if (language !== currentLanguage) {
      otherLanguage = language;
    }
  });
  const pages = [
    { en: 'courses', de: 'kurse' },
    { en: 'about-us', de: 'über-uns' },
    { en: 'contact', de: 'kontakt' },
    { en: 'apply-now', de: 'los-gehts' },
    { en: 'careers', de: 'karriere' },
    { en: 'why-is-it-free', de: 'warum-ist-es-kostenlos' },
    { en: 'events', de: 'events' },
    { en: 'bootcamps-we-work-with', de: 'bootcamps-we-work-with' },
    { en: 'professional-certificates', de: 'professional-certificates' },
  ];
  const subPages = [
    { en: 'compass-course-digital-skills-jobs', de: 'compass-kurs-digital-skills-jobs' },
    { en: 'code-mentor-web-development', de: 'ihr-personliches-intro-zu-web-development' },
    { en: 'tech-mentor', de: 'tech-mentor' },
  ];

  // Home page
  if (locationBits.length === 2) {
    return `/${otherLanguage}`;

    // Pages with dynamic slugs
  } else if (locationBits.length === 4) {
    const page = pages.find((page) => page[currentLanguage] === locationBits[2]);
    const subPage = subPages.find((page) => page[currentLanguage] === locationBits[3]);
    if (page && page[otherLanguage]) {
      return `/${otherLanguage}/${page[otherLanguage]}/${subPage[otherLanguage]}`;
      // '/courses/:slug'
    } else {
      return `/${otherLanguage}/${locationBits[2]}/${locationBits[3]}`;
      // '/partner/:partner'
      // '/legal/:slug'
    }

    // Non-translated
  } else if (['blog', 'faq', 'jobcenter'].includes(locationBits[2])) {
    return `/${otherLanguage}/${locationBits[2]}`;

    // Manual assignment
  } else {
    const page = pages.find((page) => page[currentLanguage] === locationBits[2]);
    if (page && page[otherLanguage]) {
      return `/${otherLanguage}/${page[otherLanguage]}`;
    }
    return `/${otherLanguage}`;
  }
};

export default function Header() {
  const location = useLocation()
  const availableLanguages = Object.keys(NavigationItems);
  const locationBits = useLocation().pathname.split('/');
  const currentLanguage = locationBits[1] || availableLanguages[0];
  const isLandingPage = utils.isLandingPage();
  const [cookies] = useCookies()
  const isCompassTool = [
    '/en/compass-tool',
    '/en/compass-tool/',
    '/en/compass-tool/results',
    '/en/compass-tool/results/',
  ].includes(window.location.pathname);
  const isWebinarLandingPage = [
    '/en/landing-page-startsteps-webinar'
  ].includes(window.location.pathname)
  const isReSkill = [
    '/en/reskill',
  ].includes(window.location.pathname);
  const isFirstSteps = location.pathname.endsWith("landing-page-first-steps-to-jobs-in-tech")
  const NavItems = [];
  const LanguageItems = [];
  if (NavigationItems[currentLanguage]) {
    NavigationItems[currentLanguage].forEach((page, index) => {
      let classes = 'header__nav__item';
      if (page.hideOnDesktop) {
        classes += ' hide-on-desktop';
      }
      if (page.isButton) {
        NavItems.push(
          <React.Fragment key={index}>
            <div className="header__nav__button">
              <Button
                label={page.label}
                link={`/${currentLanguage}/${page.slug}`}
                variant="small"
              />
            </div>
            <div className="header__nav__button-mobile">
              <Button
                label={page.label}
                link={`/${currentLanguage}/${page.slug}`}
                onClick={() => {
                  setTimeout(() => {
                    setIsMenuOpen(false);
                  }, 800);
                }}
              />
            </div>
          </React.Fragment>
        );
      } else if (page.children) {
        const children = [];
        page.children.forEach((subPage, index) => {
          children.push(
            <div
              key={subPage.slug}
              className="navigation-dropdown-item"
            >
              <NavLink
                to={{
                  pathname: `/${currentLanguage}/${page.slug && `${page.slug}/`}${subPage.slug}`,
                }}
                exact={true}
                activeClassName="header__nav__item--active"
                onClick={() => {
                  setTimeout(() => {
                    setIsMenuOpen(false);
                  }, 800);
                }}
              >
                <div className="between-align-start">
                  <img
                    className="hands-image"
                    src={require(`assets/${subPage.image}`)}
                    alt=""
                  />
                  <div>
                    <p className="header__nav__item__new__label">{subPage.label}</p>
                    <p className="header__nav__item__new__sub-label">{subPage.subLabel}</p>
                    {subPage.subSubLabel && <p className="header__nav__item__new__sub-sub-label">{subPage.subSubLabel}</p>}
                  </div>
                </div>
              </NavLink>
            </div>
          );
        });
        const isParentActive = locationBits[2] === page.slug;

        NavItems.push(
          <div
            key={page.label}
            className={`header__nav__item ${isParentActive && 'header__nav__item--active'}`}
          >
            <span>
              {page.label}
            </span>
            <div className={`header__nav__item__new__dropdown ${page.dropdownWide && "navigation-wide-dropdown"} ${page.dropdownAlignLeft && "navigation-align-left-dropdown"}`}>
              {children}
            </div>
          </div>)
      } else {
        NavItems.push(
          <NavLink
            key={page.slug}
            to={{
              pathname: `/${currentLanguage}/${page.slug}`,
            }}
            exact={true}
            className={classes}
            activeClassName="header__nav__item--active"
            onClick={() => {
              setTimeout(() => {
                setIsMenuOpen(false);
              }, 800);
            }}
          >
            <span>{page.label}</span>
          </NavLink>
        );
      }
    });
  }
  availableLanguages.forEach((language) => {
    if (language !== currentLanguage) {
      const pathname = findRelatedPage(locationBits);
      LanguageItems.push(
        <NavLink
          key={language}
          to={{
            pathname,
          }}
          className="header__nav__language__list__item"
          onClick={() => {
            setIsMenuOpen(false);
          }}
        >
          {language.toUpperCase()}
        </NavLink>
      );
    }
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let menuToggleClass = 'header__menu-toggle';
  let navClass = 'header__nav';
  const body = document.querySelector('body');
  if (isMenuOpen) {
    menuToggleClass += ' header__menu-toggle--open';
    navClass += ' header__nav--open';
    if (body) {
      body.classList.add('overlay');
    }
  } else if (body) {
    body.classList.remove('overlay');
  }

  const [bannerText, setBannerText] = useState(null);
  const [bannerSubtext, setBannerSubtext] = useState(null);
  useEffect(() => {
    api.getContent('header-banner', 'en', true)
      .then(({ content }) => {
        setBannerText(content[`banner_text_${currentLanguage}`]);
        setBannerSubtext(content[`banner_subtext_${currentLanguage}`]);
      });
  }, [currentLanguage]);
  const [isBusy, setIsBusy] = useState(false);
  const [isApplyFormVisible, setIsApplyFormVisible] = useState(false);
  const [isSupportFormVisible, setIsSupportFormVisible] = useState(false);
  const [wasFormSent, setWasFormSent] = useState(false);
  const [hasInvalidFields, setHasInvalidFields] = useState(true);
  const [showRegisteredError, setShowRegisteredError] = useState(false);
  const [reskillHeaderContent, setReskillHeaderContent] = useState({});
  const submitForm = () => {
    if (!hasInvalidFields) {
      setIsBusy(true);
      const customFields = {
        '19ddc486147c0bed2f2d0ca5a540854ef33bdf69': isApplyFormVisible ? 'ReSkill Application' : 'ReSkill Corporate Support',
      };
      const userData = {
        fn: firstName,
        ln: lastName,
        em: email,
      };
      const customData = {};
      if (isApplyFormVisible) {
        customFields.phone = phone;
        customFields['8a2d9fc96a0b7d037839d71aa4b3acc774c9b9c8'] = registered;
        userData.ph = phone;
        customData.registered = registered;
      } else if (isSupportFormVisible) {
        customFields['f394b2ce194ed31252ece2d2f515d094f54269ba'] = company;
        customFields['73a29c62459f7032284650a188459026b47b9ed6'] = support;
        customData.company = company;
        customData.support = support;
      }
      const pipeline = isSupportFormVisible ? 17 : false;
      api.addPerson(firstName, lastName, email, customFields, 'ReSkill', pipeline)
        .then(async () => {
          await api.trackFbEvent({
            eventName: "Decision Results",
            externalId: cookies.session,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: isApplyFormVisible ? phone : null,
            customDataObj: customData
          })
          setWasFormSent(true);
          setIsBusy(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  const wasAnsweredPositively = (str) => {
    return ['yes', 'ja'].includes(str.toLowerCase());
  }
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [company, setCompany] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [support, setSupport] = useState(false);
  useEffect(() => {
    if (isApplyFormVisible) {
      setHasInvalidFields(!firstName || !lastName || !email || !registered);
    } else if (isSupportFormVisible) {
      setHasInvalidFields(!firstName || !lastName || !email || !company || !support);
    }
  }, [firstName, lastName, email, company, registered, support, isApplyFormVisible, isSupportFormVisible]);
  useEffect(() => {
    window.setIsApplyFormVisible = setIsApplyFormVisible;
    window.setIsSupportFormVisible = setIsSupportFormVisible;
    api.getContent('reskill-header', 'en', true)
      .then(({ content }) => {
        setReskillHeaderContent(content);
      });
  }, [currentLanguage]);

  if (isFirstSteps) {
    return null
  }

  if (isReSkill) {
    return (
      <header className="header-reskill">
        <div
          className="full-width-outer"
          style={{
            backgroundImage: `url(${reskillHeaderContent.image})`,
          }}
        >
          <div className="header-reskill__gradient">
            <div className="full-width-inner">
              <div className="row">
                <div className="col-1 col-0-sm" />
                <div className="col-10 col-12-sm">
                  <div className="header-reskill__logo-wrapper">
                    <img
                      className="header-reskill__logo hide-on-mobile"
                      src={require('assets/reskill-logo.png')}
                      alt=""
                    />
                    <img
                      className="header-reskill__logo hide-on-desktop"
                      src={require('assets/reskill-logo-mobile.png')}
                      alt=""
                    />
                  </div>
                  <h1 className="header-reskill__title">{reskillHeaderContent.title}</h1>
                  <p className="header-reskill__text">{reskillHeaderContent.text}</p>
                  <div className="header-reskill__buttons">
                    <Button
                      label={reskillHeaderContent.button_1_label}
                      onClick={() => {
                        setIsApplyFormVisible(true);
                        const input = document.getElementById('reskill-form-input');
                        if (input) {
                          input.focus();
                        }
                      }}
                      variant="transparent-blue-alt2"
                    />
                    <Button
                      label={reskillHeaderContent.button_2_label}
                      onClick={() => {
                        setIsSupportFormVisible(true);
                        const input = document.getElementById('reskill-form-input2');
                        if (input) {
                          input.focus();
                        }
                      }}
                      variant="transparent-white"
                    />
                  </div>
                </div>
                <div className="col-1 col-0-sm" />
              </div>
            </div>
          </div>

          {Object.keys(reskillHeaderContent).length && <>
            <div className={`header-reskill__form__blocker${isApplyFormVisible ? ' header-reskill__form__blocker--show' : ''}`}>
              <div className="header-reskill__form">
                <img
                  className="header-reskill__form__close"
                  src={require('assets/icons/close.svg')}
                  alt=""
                  onClick={() => { setIsApplyFormVisible(false) }}
                />
                {wasFormSent ? (
                  <div
                    className="header-reskill__form__text"
                    dangerouslySetInnerHTML={
                      { __html: utils.cleanText(reskillHeaderContent.form_success_message, true) }
                    }
                  />
                ) : (
                    <>
                      <div className="header-reskill__form__text">{reskillHeaderContent.form_text}</div>
                      <div className="row">
                        <div className="col-6 col-12-sm">
                          <Input
                            label={reskillHeaderContent.form_first_name_label}
                            required
                            disabled={isBusy}
                            id="reskill-form-input"
                            onChange={(val, isValid) => {
                              setFirstName(isValid ? val : false);
                            }}
                          />
                        </div>
                        <div className="col-6 col-12-sm">
                          <Input
                            label={reskillHeaderContent.form_last_name_label}
                            required
                            disabled={isBusy}
                            onChange={(val, isValid) => {
                              setLastName(isValid ? val : false);
                            }}
                          />
                        </div>
                      </div>
                      <Input
                        label={reskillHeaderContent.form_email_label}
                        type="email"
                        required
                        disabled={isBusy}
                        onChange={(val, isValid) => {
                          setEmail(isValid ? val : false);
                        }}
                      />
                      <Input
                        label={reskillHeaderContent.form_phone_label}
                        disabled={isBusy}
                        onChange={(val, isValid) => {
                          setPhone(isValid ? val : false);
                        }}
                      />
                      <Dropdown
                        language={currentLanguage}
                        label={reskillHeaderContent.form_registered_input_label}
                        items={reskillHeaderContent.form_registered_items.map((item) => item.item)}
                        required={true}
                        disabled={isBusy}
                        onChange={(val, isValid) => {
                          const allGood = isValid && wasAnsweredPositively(val)
                          setRegistered(allGood ? val : false);
                          setShowRegisteredError(!allGood);
                        }}
                      />
                      {showRegisteredError && (
                        <div
                          className="header-reskill__form__error"
                          dangerouslySetInnerHTML={
                            { __html: utils.cleanText(reskillHeaderContent.form_registered_error_message, true) }
                          }
                        />
                      )}
                      <div className="header-reskill__form__button">
                        <Button
                          label={reskillHeaderContent.form_button_label}
                          onClick={() => {
                            submitForm();
                          }}
                          busy={isBusy}
                          disabled={hasInvalidFields}
                          variant="small transparent-blue-alt"
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className={`header-reskill__form__blocker${isSupportFormVisible ? ' header-reskill__form__blocker--show' : ''}`}>
              <div className="header-reskill__form">
                <img
                  className="header-reskill__form__close"
                  src={require('assets/icons/close.svg')}
                  alt=""
                  onClick={() => { setIsSupportFormVisible(false) }}
                />
                {wasFormSent ? (
                  <div
                    className="header-reskill__form__text"
                    dangerouslySetInnerHTML={
                      { __html: utils.cleanText(reskillHeaderContent.form_success_message, true) }
                    }
                  />
                ) : (
                    <>
                      <div className="header-reskill__form__text">{reskillHeaderContent.form_text}</div>
                      <div className="row">
                        <div className="col-6 col-12-sm">
                          <Input
                            label={reskillHeaderContent.form_first_name_label}
                            required
                            disabled={isBusy}
                            id="reskill-form-input2"
                            onChange={(val, isValid) => {
                              setFirstName(isValid ? val : false);
                            }}
                          />
                        </div>
                        <div className="col-6 col-12-sm">
                          <Input
                            label={reskillHeaderContent.form_last_name_label}
                            required
                            disabled={isBusy}
                            onChange={(val, isValid) => {
                              setLastName(isValid ? val : false);
                            }}
                          />
                        </div>
                      </div>
                      <Input
                        label={reskillHeaderContent.form_email_label}
                        type="email"
                        required
                        disabled={isBusy}
                        onChange={(val, isValid) => {
                          setEmail(isValid ? val : false);
                        }}
                      />
                      <Input
                        label={reskillHeaderContent.form_company_label}
                        required={true}
                        disabled={isBusy}
                        onChange={(val, isValid) => {
                          setCompany(isValid ? val : false);
                        }}
                      />
                      <Dropdown
                        label={reskillHeaderContent.form_support_input_label}
                        items={reskillHeaderContent.form_support_items.map((item) => item.item)}
                        required={true}
                        disabled={isBusy}
                        onChange={(val, isValid) => {
                          setSupport(isValid ? val : false);
                        }}
                      />
                      <div className="header-reskill__form__button">
                        <Button
                          label={reskillHeaderContent.form_button_label}
                          onClick={() => {
                            submitForm();
                          }}
                          busy={isBusy}
                          disabled={hasInvalidFields}
                          variant="small transparent-blue-alt"
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
          </>
          }

        </div>
      </header>
    )
  }
  return (
    <header className={`header${isCompassTool ? ' header--always-small' : ''}`}>
      <div className="full-width-outer">
        <div className="full-width-inner">
          <Link
            to={{
              pathname: `/${currentLanguage}`,
            }}
            className="header__logo"
          />

          {!isLandingPage && (
            <>
              <div
                className={menuToggleClass}
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <div className="header__menu-toggle__one" />
                <div className="header__menu-toggle__two" />
                <div className="header__menu-toggle__three" />
              </div>
              <nav
                className={navClass}
                role="navigation"
              >
                {NavItems}
                <div className="header__nav__language">
                  <div className="header__nav__language__current">
                    {currentLanguage}
                    <div className="header__nav__language__list">
                      {LanguageItems}
                    </div>
                  </div>
                </div>

              </nav>
            </>
          )}

          {(bannerText && !isCompassTool && !isWebinarLandingPage) && (
            <div className="header__banner">
              {bannerText}
              {bannerSubtext && (<span>&mdash; { bannerSubtext} &mdash;</span>)}
            </div>
          )}

        </div>
      </div>
    </header>
  );
}
