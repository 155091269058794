import React from 'react';

// Helper
import utils from 'utils';

// Components
import Button from 'components/Button';

// Styles
import './Providers.scss';

export default function Providers(props) {
  const {
    title,
    text,
    items,
    tooltipButtonLabel,
    isCourse,
    isStatic,
  } = props;

  const logos = [];
  if (items) {
    [...Array(20).keys()].forEach((idx) => {
      const index = idx + 1;
      let tooltip = null;
      if (items[`description${index}`] && items[`link${index}`]) {
        tooltip = (
          <div className="providers__logos__item__tooltip">
            <div className="providers__logos__item__tooltip-wrapper">
              <div dangerouslySetInnerHTML={{__html: utils.cleanText(items[`description${index}`], true)}} className="providers__logos__item__tooltip__description">
              </div>
              <div className="providers__logos__item__tooltip__button">
                <Button
                  label={tooltipButtonLabel}
                  link={items[`link${index}`]}
                  variant="transparent-white"
                />
              </div>
            </div>
          </div>
        );
      }
      logos.push(
        <div
          className="col-3 col-6-sm"
          key={index}
        >
          <div className="providers__logos__item">
            <img
              src={items[`image${index}`]}
              alt={items[`title${index}`]}
            />
            {!isStatic && tooltip}
          </div>
        </div>
      );
    });
  }
  let classes = 'providers slider-block';
  if (isCourse) {
    classes += ' providers-alt';
  }
  return (
    <div className={classes}>
      <div className="full-width-outer">
        <div className="full-width-inner">
          <div className="row">
            <div className="col-1 col-0-sm" />
            <div className="col-6 col-12-sm slider">
              <h2 className="providers__title">
                {title}
              </h2>
              <div
                className="providers__text"
                dangerouslySetInnerHTML={
                  { __html: utils.cleanText(text, true) }
                }
              ></div>
            </div>
            <div className="col-5 col-0-sm" />
          </div>
          <div className="row">
            <div className="col-2 col-0-sm" />
            <div className="col-7 col-12-sm slider slider-delay-1">
              <div className="providers__logos">
                <div className="row">
                  {logos}
                </div>
              </div>
            </div>
            <div className="col-3 col-0-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}
