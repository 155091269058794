import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import styles from './Button.module.scss';

export default function Button(props) {
  const {
    label,
    disabled,
    busy,
    variant,
    onClick,
    link,
  } = props;

  let className = styles.button
  if (variant) {
    const variantBits = variant.split(' ');
    variantBits.forEach((item) => {
      className += ` ${styles[`button--${item}`]}`;
    });
  }
  if (disabled) {
    className += ` ${styles['button--disabled']}`;
  }
  if (busy) {
    className += ` ${styles['button--busy']}`;
  }
  if (link && onClick) {
    return (
      <Link
        to={ link }
        onClick={ () => {
          if (!disabled && !busy) {
            onClick();
          }
        }}
        className={ className }
      >
        { label }
      </Link>
    );
  } else if (link) {
    const isInternalLink = link.substr(0, 7) !== 'http://' && link.substr(0, 8) !== 'https://';
    if (disabled || busy) {
      return (
        <div className={ className }>
          { label }
        </div>
      );
    } else if (isInternalLink){
      return (
        <Link
          to={ link }
          className={ className }
          >
          { label }
        </Link>
      );
    } else {
      return (
        <a
          className={ className }
          href={ link }
          target="_blank"
          rel="noopener noreferrer"
        >
          { label }
        </a>
      );
    }
  } else if (onClick) {
    return (
      <button
        className={ className }
        onClick={ () => {
          if (!disabled && !busy) {
            onClick();
          }
        }}
      >
        { label }
      </button>
    );
  }
  return null;
}
