import React, { useState, useRef } from 'react';

// Styles
import './SingleSelectDropdown.scss';

const SingleSelectDropdown = ({ options, setOptions, label, defaultOption = "Please Select" }) => {
    const [open, setOpen] = useState(false)
    const selectorRef = useRef()

    const renderSelected = () => {
        if (!options.find(option => !option.checked)) {
            return `All`
        } else if (!options.find(option => option.checked)) {
            return defaultOption
        } else {
            const selected = options
                .filter(option => option.checked)
                .map(option => option.label)
                .join(", ")

            if (selected.length > 26) {
                const cut = selected.substring(0, 26)
                return cut.concat("...")
            }

            return selected
        }
    }

    const handleSelectorClick = () => {
        if (!open) {
            closeOnClickOutside()
        }
        setOpen(cur => !cur)
    }

    const closeOnClickOutside = () => {
        document.onmousedown = handleDocumentClick
    }

    const handleDocumentClick = (event) => {
        if (selectorRef && selectorRef.current && selectorRef.current.contains(event.target)) {
            return undefined
        } else {
            setOpen(false)
            document.onmousedown = null
        }
    }

    const handleCheckboxChange = (value) => {
        setOptions(cur => cur.map((option) => {
            if (option.value === value) {
                return { ...option, checked: !option.checked }
            } else {
                return { ...option, checked: false }
            }
        }))
    }

    return (
        <div ref={selectorRef} className="selector">
            <div className="container">
                <span className="label">{label}</span>
                <div onClick={handleSelectorClick} className="flex-between open-selector">
                    <div className="selected">{renderSelected()}</div>
                    <div className="icon">
                        &#9660;
                    </div>
                </div>
            </div>
            {open && <ul className="options">
                <div className="wrapper">
                    {options.map(option => (
                        <li key={option.value} className="option">
                            <label className="flex-center selector-checkbox">
                                <input checked={option.checked} onChange={() => handleCheckboxChange(option.value)} className="radio" type="radio" />
                                <span>{option.label}</span>
                            </label>
                        </li>
                    ))}
                </div>
            </ul>}
        </div>
    )
}

export default SingleSelectDropdown;